<template>
  <div>
    <div
      class="qtype-color"
      :style="`background-color: ${color};`"
    ></div>
    {{ color }}
  </div>
</template>

<script>
    export default {
      props: {
        color: String,
      },
    };
</script>

<style lang="scss" scoped>
.qtype-color {
  height: 13px;
  width: 13px;
  display: inline-block;
  margin-right: 4px;
  border: 1px solid var(--cream-color);
}
</style>