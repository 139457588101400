<template>
  <div class="form-group">
    <label :for="generatedId" class="custom-checkbox">
      <input
        :id="generatedId"
        :value="modelValue"
        @change="updateValue"
        class="custom-checkbox__input"
        :class="{ noninteractive: noninteractive }"
        type="checkbox"
        :checked="checked"
        :req="req"
        :disabled="disabled || noninteractive"
      />
      <span class="custom-checkbox__label">
        {{ label }}
      </span>
    </label>
  </div>
</template>

<script>
import { ref, toRef } from "@vue/reactivity";
import { onBeforeMount } from "@vue/runtime-core";
export default {
  name: "custom-checkbox",
  props: {
    modelValue: {
      required: false,
    },
    name: {
      type: String,
    },
    label: {
      type: String,
    },
    req: {
      default: false,
    },
    value: {
      required: false,
    },
    fromValue: {
      type: Boolean,
      default: false,
    },
    checked: {
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    noninteractive: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const needValue = toRef(props, "fromValue"),
      checkboxValue = toRef(props, "value");
    const updateValue = (event) => {
      context.emit(
        "update:modelValue",
        needValue.value ? (event.target.checked ? checkboxValue.value : event.target.checked) : event.target.checked
      );
    };
    const generatedId = ref("checkbox");

    const generateId = () => {
      let alphabet = "abcdefghijklmnopqrstuvwxyz";
      for (let i = 0; i < 6; i++) {
        generatedId.value +=
          alphabet[Math.round(Math.random() * (alphabet.length - 1))];
      }
    };

    onBeforeMount(() => {
      generateId();
    });

    return {
      updateValue,
      needValue,
      checkboxValue,
      generatedId,
    };
  },
};
</script>

<style lang="scss" scoped>
.form-group {
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 400;
  justify-content: center;
  justify-content: flex-start;
  .custom-checkbox {
    min-height: 22px;
    min-width: 22px;
    margin-bottom: 12px;
    margin-top: 6px;
    position: relative;
    display: block;
    padding-left: 38px;
    cursor: pointer;

    &__input {
      position: absolute;
      z-index: -1;
      opacity: 0;
    }

    &__input:checked ~ .custom-checkbox__label::before {
      border-color: #2cc185;
      background-color: #fff;
      color: #2cc185;
    }

    &__input:checked ~ .custom-checkbox__label::after {
      content: "\f00c";
      font-family: "Font Awesome 5 Pro";
      font-size: 20px;
      font-weight: 300;
      text-align: center;
      color: #2cc185;
      line-height: 1;
    }

    &__input:disabled {
      cursor: default;
    }

    &__input:disabled ~ .custom-checkbox__label {
      color: #ccc;
      cursor: default;
    }

    &__input.noninteractive:disabled ~ .custom-checkbox__label {
      color: black;
      cursor: inherit;
    }

    &__label {
      position: unset;
      white-space: break-spaces;
      padding-right: 5px;
      word-break: break-word;

      &::before,
      &::after {
        left: 0;
      }

      &::before {
        border: 2px solid #ebebeb;
        content: "";
        position: absolute;
        top: calc(50% - 13px);
        display: block;
        width: 27px;
        height: 27px;
        pointer-events: none;
        background-color: #fff;
      }

      &::after {
        position: absolute;
        top: calc(50% - 8.5px);
        display: block;
        width: 27px;
        height: 27px;
        content: "";
        background: no-repeat 50%/50% 50%;
      }
    }
  }
}
</style>