<template>
  <div class="text-center">
    <img
      class="cell-image"
      v-if="image && !errorImage"
      :src="`${imageUrl}/${image}`"
      alt="Изображение"
      @error="errorImage = true"
    />
    <img v-if="errorImage" class="cell-image" src="@/assets/images/error-image.png" alt="Изображение">
  </div>
</template>

<script>
import { ref } from '@vue/reactivity';
export default {
  props: {
    image: {
      type: String,
    }
  },
  setup() {
    const imageUrl = process.env.VUE_APP_BACK,
        errorImage = ref(false);
    return {
        imageUrl, errorImage
    }
  }
};
</script>

<style lang="scss" scoped>
.cell-image {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
}
</style>