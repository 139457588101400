<template>
  <div>
    <div v-if="!Array.isArray(value)">{{ value }}</div>
    <div v-else>
      <Popper v-if="value.length" placement="right" arrow class="products-popper">
        <div class="products-renderer">
          <button class="btn btn-w">
            <i class="fal fa-clipboard-list"></i>
          </button>
        </div>
        <template #content>
          <table class="product-table">
            <tr v-for="(entity, index) of value" :key="index">
              <td>{{ entity }}</td>
            </tr>
          </table>
        </template>
      </Popper>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Array]
    }
  },
}
</script>

<style>
.products-renderer {
  display: flex;
  align-items: center;
  justify-items: center;
}

.btn .fa-clipboard-list {
  margin-left: 0;
}

.product-table {
  padding-left: 15px;
}
</style>