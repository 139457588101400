<template>
  <button @click="deactivateSubscription" class="btn btn-accent">
    Деактивировать
  </button>
</template>

<script>
import {useStore} from "vuex";

export default {
  props: {
    id: Number,
  },
  setup(props) {
    const store = useStore();

    const deactivateSubscription = () => {
      store.dispatch("subscription/expireCompanySubscription", props.id);
    }

    return {
      deactivateSubscription,
    }
  }
}
</script>