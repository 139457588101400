<template>
  <Popper arrow class="report-popper">
    <div v-if="finished_tests.length">
      <div>
        <i class="fas fa-box-archive"></i>
      </div>
    </div>
    <template #content>
      <div class="list_header">
        <span>По датам</span>
      </div>
      <ul class="report_list">
        <li v-for="report in finished_tests" :key="report.id" class="link"
            :class="'download'"
            @click="() => downloadReport(report.code)">
                  <span role="button">
                    {{ report.finished_at }}
                  </span>
        </li>
      </ul>
    </template>
  </Popper>
</template>

<script>
import store from "@/store";

export default {
  name: "reports-render",
  props: {
    finished_tests: Array,
  },
  setup() {
    const downloadReport = (passing) => {
      store.dispatch("group/getReport", {
        passing: [ passing ],
      });
    }
    return {
      downloadReport
    }
  }
}
</script>

<style scoped lang="scss">

:deep(.popper),
:deep(.popper:hover),
:deep(.popper #arrow::before),
:deep(.popper #arrow::after),
:deep(.popper:hover > #arrow::before) {
  background-color: #fff;
}

.download {
  cursor: pointer;
  color: #00e287;
}

.download:hover {
  color: #00a65a;
}

.disable {
  color: #cccccc;
}

.fa-box-archive {
  cursor: pointer;
  font-size: 20px;
}

.fa-box-archive:hover {
  color: var(--hover-color)
}

.fas .disable {
  background-color: #cccccc;
}

.list_header {
  margin-bottom: 5px;
  color: #3D3D3D;
}

.report_list {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}
</style>