<template>
  <div v-if="type === 'test'" class="row row-description">
    <div>"ТЭИ – версия для топ-руководителей"</div>
    <div>"ТЭИ – версия для линейных руководителей"</div>
  </div>
  <div v-else-if="type === 'active'" class="row">
    <div>{{ data.full.full_active_count }}</div>
    <div>{{ data.standard.standard_active_count }}</div>
  </div>
  <div v-else-if="type === 'free'" class="row">
    <div>{{ data.full.full_free_count }}</div>
    <div>{{ data.standard.standard_free_count }}</div>
  </div>
  <div v-else-if="type === 'issued'" class="row">
    <div>{{ data.full.full_issued_count }}</div>
    <div>{{ data.standard.standard_issued_count }}</div>
  </div>
  <div v-else-if="type === 'total'" class="row">
    <div>{{ data.full.full_total }}</div>
    <div>{{ data.standard.standard_total }}</div>
  </div>
</template>

<script>
export default {
  props: {
    data: Object,
    type: String,
  }
}
</script>

<style>
.row {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}

.row-description {
  padding-top: 10px;
}
</style>
