<template>
  <div class="edit_button">
    <router-link
      v-if="dest && res"
      :to="`/${dest}/${value}/${res}`"
    >
      <i class="fal fa-pen"></i>
    </router-link>
    <router-link
      v-else-if="dest"
      :to="`/${dest}/${value}`"
    >
      <i class="fal fa-pen"></i>
    </router-link>

    <router-link v-if="group" :to="`/group/edit/${value}`">
      <i class="fal fa-users"></i>
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number]
    },
    dest: String,
    res: String,
    group: Boolean,
  },
};
</script>
<style>
.edit_button {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
  width: 50px;
  height: 50px;
}
</style>
