<template>
  <div class="home-block home-block__rows w-full">
    <div>
      <div class="home-block__title"><b>Мои курсы</b></div>
      <div
        v-for="course in courses"
        :key="course.id"
        class="diagram-sm-row sm-row"
      >
        <a class="navbar-sec__link" :href=course.url target="_blank">{{ course.name }}</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "@vue/runtime-core";
import { useStore } from "vuex";

export default {
  name: "courses",
  props: {
    courses: Array,
  },
  setup() {
    const store = useStore();
    const statuses = {
      new: "Новый",
      payed: "Оплачен",
      instruction: "Инструкция",
      questionnaire: "Опросник",
      in_progress: "В процессе",
      finished: "Завершен",
    };

    const courseLink = computed(() => store.state.courseLink);

    return {
      statuses,
      courseLink,
    };
  },
};
</script>

<style scoped lang="scss">
.sm-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 13px 25px;
  border-bottom: 1px solid var(--cream-color);

  &__title {
    font-size: 16px;
    max-width: 80%;
  }

  &__btn {
    min-width: 138px;
    margin-left: 15px;
  }
}

</style>