<template>
  <div>
    <div v-for="(text, id) of Object.values(value)" :key="id">
      {{ text }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: Object
  },
}
</script>

<style lang="scss" scoped>
a {
  width: 100%;
  display: block;

  &:hover {
    color: var(--hover-color);
  }
}
</style>