<template>
  <div>
    <span
      v-if="value === 'new' || value === 'need_approve'"
      class="new-label"
    >
      Требует проверки
    </span>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: String,
    }
  },
};
</script>
<style lang="scss" scoped>
.new-label {
  background-color: var(--main-color);
  color: white;
  border-radius: 3px;
  padding: 5px 8px;
}
</style>
