<template>
  <div>
    <button
      v-if="
        status === 'Завершен' &&
        type !== translate['empty'] &&
        allowed &&
        !reportLink
      "
      @click="getReport"
      class="btn btn-sm btn-w"
    >
      Открыть отчет
    </button>
    <router-link
      v-if="status !== 'Завершен'"
      :to="`/passing/${value}`"
      class="btn btn-sm btn-w"
    >
      Перейти к прохождению
    </router-link>
    <div v-if="isPreloader" class="btn-preloader">
      <mini-preloader />
    </div>
    <a class="report-link" v-if="reportLink" :href="reportLink" target="_blank"
      >Ссылка на отчет
    </a>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import MiniPreloader from "../../Technical/MiniPreloader.vue";
import { useStore } from "vuex";
import reportTranslate from "@/mixins/dictionaries/reportTranslate";
import { notify } from "@kyvg/vue3-notification";

export default {
  components: { MiniPreloader },
  props: {
    status: String,
    type: String,
    allowed: Boolean,
    value: String,
  },
  setup(props) {
    const isPreloader = ref(false);
    const store = useStore();
    const translate = reportTranslate;
    const reportLink = ref("");

    const getReport = () => {
      isPreloader.value = true;
      store
        .dispatch("test/getReportLink", props.value)
        .then(({ data }) => {
          reportLink.value = data;
          window.open(data, "_blank");
        })
        .catch((error) => {
          notify({
            title: "Произошла ошибка",
            text: error,
            type: "error",
          });
        })
        .finally(() => (isPreloader.value = false));
    };
    return {
      getReport,
      isPreloader,
      translate,
      reportLink,
    };
  },
};
</script>

<style lang="scss" scoped>
.btn {
  font-size: 15px;
  padding: 7px 6px;
  line-height: 110%;
  i {
    font-size: 14px;
    padding: 2px;
  }
}

.report-link {
  color: var(--main-color);
}
</style>
